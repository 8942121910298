import './public-path';
/* eslint-disable react/jsx-filename-extension */
import {
  setCustomElementsScopingSuffix,
  setCustomElementsScopingRules,
} from '@ui5/webcomponents-base/dist/CustomElementsScope';
import React from 'react';
import { createRoot } from 'react-dom/client';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import '@ui5/webcomponents-localization/dist/Assets-static.js';
import { setLanguage as setUi5Language } from '@ui5/webcomponents-base/dist/config/Language.js';
import '@ui5/webcomponents-localization/dist/Assets.js';
import '@ui5/webcomponents-react/dist/Assets.js';
import App from './App';
import axiosWrapper from './axios/axiosWrapper';
import './styles/index.less';

const { setLanguage, getUi5Language } = eureka.I18nProvider;
const { eventBus } = eurekaMgrs;

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  setCustomElementsScopingSuffix(process.env.APP_NAME);
  setCustomElementsScopingRules({
    include: [/^ui5-/],
    exclude: [/^ui5-object-page-anchor-tab/],
  });
}

/* istanbul ignore next */
eventBus.on('i18n-update', (langCode) => {
  setLanguage(langCode);
  setUi5Language(getUi5Language(langCode));
});

const root = createRoot(document.getElementById('osta-shell-ui-app'));
root.render(<App {...axiosWrapper()} />);
