export const URI = {
  CDN: 'https://cdn.eurekacloud.io',
};

export const ICON = {
  APPROVAL: 'sap-icon--approvals',
};

export const ROUTER = {
  LIST: '/settlement/list',
  APPROVALS: '/approvals',
  CONFIG_SETUP: '/configuration/setup',
  LIBRARY: '/document-library',
  DASHBOARD: '/analytics-dashboard',
  NON_CLASSIFIED: '/aropenitem/non-classified',
  ERROR_PAGE: 'Error page',
};

export const FEATURE_FLAGS = {
  TYPE_CODE_CONFIG: 'OSTA.7902.ENABLE.TYPE.CODE.CONFIGURATION',
  DATA_TRANSFER_CONFIG: 'OSTA.6161.OUTBOUND.CONFIG.PAGE',
};
