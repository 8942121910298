import { axiosCreate } from './eureka';

export const usersAxios = axiosCreate({
  baseURL: '/api/user-configuration-plus/business/v1/users',
}).instance;

export const languagesAxios = axiosCreate({
  baseURL: '/api/ome-user/business/v1/current-language-setting',
}).instance;

export const fetchMe = async () => usersAxios.get('/me');

export const patchMe = async (payload) => usersAxios.patch('/me', payload);

const languages = [
  {
    languageTranslationKey: 'Language_Czech',
    languageCode: 'cs-CZ',
  },
  {
    languageTranslationKey: 'Language_Dutch',
    languageCode: 'nl-NL',
  },
  {
    languageTranslationKey: 'Language_English',
    languageCode: 'en-US',
  },
  {
    languageTranslationKey: 'Language_French',
    languageCode: 'fr-FR',
  },
  {
    languageTranslationKey: 'Language_German',
    languageCode: 'de-DE',
  },
  {
    languageTranslationKey: 'Language_Italian',
    languageCode: 'it-IT',
  },
  {
    languageTranslationKey: 'Language_Japanese',
    languageCode: 'ja-JP',
  },
  {
    languageTranslationKey: 'Language_Norwegian',
    languageCode: 'no-NO',
  },
  {
    languageTranslationKey: 'Language_Polish',
    languageCode: 'pl-PL',
  },
  {
    languageTranslationKey: 'Language_Portuguese',
    languageCode: 'pt-BR',
  },
  {
    languageTranslationKey: 'Language_Chinese_Simplified',
    languageCode: 'zh-CN',
  },
  {
    languageTranslationKey: 'Language_Spanish',
    languageCode: 'es-ES',
  },
  {
    languageTranslationKey: 'Language_Swedish',
    languageCode: 'sv-SE',
  },
  {
    languageTranslationKey: 'Language_Turkish',
    languageCode: 'tr-TR',
  },
];
// TODO: We have a mock response here but maybe in future this would be coming from an API Call.
export const fetchLanguages = async () =>
  Promise.resolve({
    data: languages,
  });
