/* eslint-disable implicit-arrow-linebreak */
import eurekaMgrs from '@eureka/ui-managers';
import '@ui5/webcomponents-icons/dist/copy';
import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';
import '@ui5/webcomponents-icons/dist/log.js';
import '@ui5/webcomponents-icons/dist/menu';
import '@ui5/webcomponents-icons/dist/menu2';
import '@ui5/webcomponents-icons/dist/product';
import '@ui5/webcomponents-icons/dist/user-settings.js';
import '@ui5/webcomponents-icons/dist/employee.js';
import '@ui5/webcomponents-icons/dist/feedback';
import {
  Avatar,
  Button,
  ButtonDesign,
  Popover,
  ShellBar,
  StandardListItem,
  List,
  ShellBarItem,
} from '@ui5/webcomponents-react';
import eurekaComponents from 'eureka';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { usePxFeedback } from 'hooks';
import LogoutForm from './LogoutForm';
import logo from './logo';
import * as reduxActions from './redux/actions';
import UserProfile from './UserProfile';

const { withTranslation } = eurekaComponents.I18nProvider;
const { eventBus } = eurekaMgrs;
const { addConfig } = eurekaMgrs.ConfigManager;

export const handleProfileClick = ({ event, profileMenuRef }) => {
  profileMenuRef?.current?.showAt(event.detail.targetRef);
};

const closeProfileMenu = (profileMenuRef) => {
  if (profileMenuRef?.current?.close) {
    profileMenuRef.current.close();
  }
};

const openProfileSetting = (event, config, t) => {
  closeProfileMenu();
  eventBus.emit('dialog-show-hide', '', {
    type: 'user-profile',
    data: {
      showDialog: true,
      title: t('Header_ProfileSettings', 'Settings', { type: 'tit', desc: '' }),
      content: () => <UserProfile config={config} eventBus={eventBus} />,
    },
  });
};

const logout = (profileMenuRef) => {
  closeProfileMenu(profileMenuRef);

  eventBus.emit('dialog-show-hide', '', {
    type: 'user-logout',
    data: {
      showDialog: true,
      title: 'Logout',
      content: <LogoutForm eventBus={eventBus} />,
    },
  });
};

export const handleSidenav = ({ showMenu, actions }) => {
  if (showMenu) {
    actions?.hideMenu?.call();
  } else {
    actions?.showMenu?.call();
  }
  eventBus.emit('sidenav-show-hide', null, { collapsed: showMenu });
  addConfig('SidenavCollapsed', showMenu);
};

export const Header = ({ t, common, actions, config, user }) => {
  const profileMenuRef = useRef();
  const { showMenu } = common;
  const userInitials = user?.avatarIcon?.value;

  const { init, isInitialized, handleFeedback } = usePxFeedback();
  useEffect(() => {
    if (isInitialized || !user) return;

    const tenantId = user.tenantId?.toString();
    if (tenantId) {
      init(tenantId);
    }
  }, [init, isInitialized, user]);

  useEffect(() => {
    addConfig('SidenavCollapsed', !showMenu);
    if (actions?.startWebsocket) {
      actions.startWebsocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Button
        data-testid="showMenuBtn"
        style={{ position: 'absolute', zIndex: 1, color: '#223548', top: '10px', left: '8px' }}
        icon={showMenu ? 'menu' : 'menu2'}
        accessibleName={showMenu ? t('Close_Menu') : t('Open_Menu')}
        tooltip={showMenu ? t('Close_Menu') : t('Open_Menu')}
        design={ButtonDesign.Transparent}
        onClick={() => handleSidenav({ showMenu, actions })}
      />
      <ShellBar
        className="shellbar"
        logo={<img alt="SAP Logo" src={logo} />}
        primaryTitle={t('AppName')}
        profile={
          <Avatar
            data-testid="userProfileAvatar"
            icon={userInitials ? '' : 'employee'}
            initials={userInitials}
            accessibleName={t('Profile')}
          />
        }
        data-testid="osta-shell-app-title"
        onProfileClick={(event) => handleProfileClick({ event, profileMenuRef })}
      >
        {isInitialized && (
          <ShellBarItem data-testid="pxFeedbackBtn" onClick={handleFeedback} icon="feedback" />
        )}
      </ShellBar>

      <Popover
        data-testid="userProfilePopover"
        ref={profileMenuRef}
        allowTargetOverlap
        horizontalAlign="Right"
        placementType="Bottom"
        preventFocusRestore={false}
      >
        <div>
          <List>
            <StandardListItem
              data-testid="userSettingsBtn"
              onKeyDown={(e) => e.key === 'Enter' && openProfileSetting(e, config, t)}
              onClick={(e) => openProfileSetting(e, config, t)}
              icon="user-settings"
              infoState="None"
              selected={false}
              type="Active"
            >
              {t('ProfileSettings', 'Settings', { type: 'but', desc: '' })}
            </StandardListItem>
            <StandardListItem
              data-testid="logOutBtn"
              onKeyDown={(e) => e.key === 'Enter' && logout(profileMenuRef)}
              onClick={() => logout(profileMenuRef)}
              infoState="None"
              icon="log"
              selected={false}
              type="Active"
            >
              {t('Logout')}
            </StandardListItem>
          </List>
        </div>
      </Popover>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...reduxActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
