import {
  List,
  ListMode,
  StandardListItem,
  Button,
  ButtonDesign,
  Title,
  TitleLevel,
} from '@ui5/webcomponents-react';
import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  Spinner,
  setLanguage,
  configManagerSetLanguage,
  MessageToast,
} from './eureka';
import { closeShellDialog } from '../../plugins/dialog.plugin';
import { fetchLanguages, fetchMe, patchMe } from './axios';
import LanguageAndRegion from './LanguageAndRegion';

const initialData = {
  userName: '',
  pictureId: null,
  email: '',
  phone: '',
  language: '',
  dateFormat: '',
  timeFormat: '',
};

const UserProfileLanguageAndRegion = 'user-profile_language_region';
const borderStyle = '1px solid #e8e8e8';

export default function UserProfile({ eventBus }) {
  const { t } = useTranslation('shell');
  const [isLoading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(UserProfileLanguageAndRegion);
  const [data, setData] = useState({ ...initialData });
  const [originalData, setOriginalData] = useState({ ...initialData });
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      return Promise.all([fetchMe(), fetchLanguages()])
        .then((results) => {
          const me = results[0].data;
          setLanguage(me.language);
          setData({
            ...me,
          });
          setOriginalData({
            ...me,
          });
          setLanguages(results[1].data || []);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getData();
  }, []);

  // trigger re-render in mfes when user profile have updates
  const notifyOthers = (user, languageChanged) => {
    try {
      if (languageChanged) {
        // tell other mfes, language is changed, only update translations,
        // no reload in this case
        eventBus?.emit('i18n-update', '', user?.language || 'en-US');
      }
      // tell other mfes, user profile is changed, mfe reloads
      eventBus?.emit('configuration-updated', 'user profile', {
        key: 'userProfile',
        data: user,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = () => {
    closeShellDialog();

    setData({
      ...originalData,
    });
  };

  const onDataPropChange = (value, propName) => {
    setData({
      ...data,
      [propName]: value,
    });
  };

  const handleSave = () => {
    const differ = {};
    let languageChanged = false;
    Object.keys(data).forEach((key) => {
      if (originalData[key] !== data[key]) {
        differ[key] = data[key];
        if (key === 'language') {
          languageChanged = true;
        }
      }
    });
    if (Object.keys(differ).length > 0) {
      let current = parseInt(data.userUpdateState, 10);
      current = Number.isNaN(current) ? 1 : current;
      differ.userUpdateState = current + 1;
    }
    setLoading(true);
    return (
      patchMe({ ...differ })
        .then((result) => {
          const user = result.data;
          console.log(user);
          setOriginalData({ ...user });
          setData({ ...user });
          notifyOthers(user, languageChanged);

          if (languageChanged) {
            setLanguage(data.language);
            configManagerSetLanguage(data.language);
          }
          MessageToast.success(
            t('UserProfile_Msg_UpdateSuccess', 'Update success', { type: 'msg', desc: '' }),
          );
          closeShellDialog();
        })
        // on language change, update language options list
        .then(() => {
          fetchLanguages().then((result) => {
            setLanguages(result.data || []);
          });
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setData({
            ...originalData,
          });
          MessageToast.error(
            t('UserProfile_Msg_UpdateFailed', 'Update fail', { type: 'msg', desc: '' }),
          );
          setLoading(false);
        })
    );
  };

  return (
    <div>
      <Title level={TitleLevel.H3} style={{ padding: '16px 24px', fontSize: '16px' }}>
        {t('UserProfile_ProfileSettings', 'Settings', { type: 'tit', desc: '' })}
      </Title>
      <div style={{ borderBottom: borderStyle, borderTop: borderStyle }}>
        <div
          style={{
            display: 'flex',
            width: '636px',
            height: '100%',
            minHeight: '330px',
            padding: 0,
          }}
        >
          <List
            style={{ width: '219px' }}
            mode={ListMode.SingleSelect}
            noDataText={t('UserProfile_Msg_NoDataAvailable', 'No data available', {
              type: 'msg',
              desc: '',
            })}
            onItemClick={(evt) => {
              setCurrentItem(evt.detail.item.id);
            }}
          >
            <StandardListItem
              icon="globe"
              data-testid="languageRegionListItem"
              id={UserProfileLanguageAndRegion}
              selected={currentItem === UserProfileLanguageAndRegion}
              description={`${(
                (data.language && data.language.split('-')[0]) ||
                '...'
              ).toUpperCase()}`}
            >
              {t('UserProfile_LanguageAndRegion', 'Language and Region', { type: 'mit', desc: '' })}
            </StandardListItem>
          </List>
          <section
            style={{
              flexGrow: 1,
              padding: '10px',
              display: 'flex',
              borderLeft: borderStyle,
            }}
          >
            {currentItem === UserProfileLanguageAndRegion &&
              data &&
              data.language &&
              languages &&
              languages.length > 0 && (
                <LanguageAndRegion
                  data={data}
                  languages={languages}
                  onDataPropChange={onDataPropChange}
                />
              )}
          </section>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '60px',
        }}
      >
        <Button
          disabled={JSON.stringify(data) === JSON.stringify(originalData)}
          design={ButtonDesign.Emphasized}
          onClick={handleSave}
          data-testid="saveButton"
        >
          {t('UserProfile_Save', 'Save', { type: 'but', desc: '' })}
        </Button>
        <Button
          style={{
            marginLeft: '1rem',
            marginRight: '0.5rem',
          }}
          onClick={handleCancel}
          data-testid="cancelButton"
        >
          {t('UserProfile_Cancel', 'Cancel', { type: 'but', desc: '' })}
        </Button>
      </div>

      {isLoading && (
        <div
          style={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            background: 'rgba(0, 0, 0, 0.01)',
            zIndex: 999,
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}
