/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/static-property-placement */
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EurekaComponents from 'eureka';
import { getCurrentRuntimeIndex } from '@ui5/webcomponents-base/dist/Runtimes';
import * as actions from './redux/actions';

// eslint-disable-next-line import/no-named-as-default
import Header from './Header';
import SidePanel from './SidePanel';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';

const { Spinner } = EurekaComponents.components;

export class DefaultLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object,
    settings: PropTypes.object,
  };

  static defaultProps = {
    children: null,
    history: null,
    settings: null,
  };

  render() {
    const { showMenu } = this.props.common;
    // Temporary fix of broken ui5 styles
    // Will be removed when the ui5 team will fix it
    // https://github.com/SAP/ui5-webcomponents/issues/7227
    const runtimeIndex = getCurrentRuntimeIndex();
    const attrs = {
      _ui5host: '',
      [`_ui5rt${runtimeIndex}`]: '',
    };
    return (
      <div className="fd-shell fd-shell--fundamentals">
        <div style={{ background: 'var(--sapShellColor)' }}>
          <Suspense fallback={<Spinner />}>
            <Header
              history={this.props.history}
              config={this.props.config}
              settings={this.props.settings}
              user={this.props.user}
              common={this.props.common}
            />
          </Suspense>
        </div>
        <div className="fd-shell__app">
          <div className="fd-app" {...attrs}>
            <div
              className={`fd-app__navigation fd-app__navigation--vertical${
                showMenu ? ' show-menu ' : ' hide-menu '
              }`}
            >
              <Suspense fallback={<Spinner />}>
                <SidePanel
                  history={this.props.history}
                  match={this.props.match}
                  config={this.props.config}
                />
              </Suspense>
            </div>
            <main className="fd-app__main">
              <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
                {this.props.children}
              </Suspense>
            </main>
          </div>
        </div>
        <ShellDialog key="default-layout-dialog" />
        <MessageToast key="default-message-toast" />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}
/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
