import eurekaMgrs from '@eureka/ui-managers';
import { MessageStrip, MessageStripDesign } from '@ui5/webcomponents-react';
import eurekaComponents from 'eureka';
import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/no-named-as-default
import DefaultLayout from './DefaultLayout';

const { ProxyHelper } = eurekaMgrs.ProxyManager;
const {
  fetchApplicationManifestAssets,
  renderApplication,
  unmountApplication,
  fetchManifest,
} = ProxyHelper;
const { Spinner } = eurekaComponents.components;

const { UserPreferenceManager, eventBus } = eurekaMgrs;

export const hasLoggedin = () => {
  const login = !!window.hasLoggedin;
  return login;
};

export const defaultGetAppPreference = () =>
  Promise.resolve({
    data: {},
  });

export const renderApp = ({ history, match, host, config, name, user, settings, container }) => {
  renderApplication(
    name,
    history,
    {
      history,
      match,
      host,
      user,
      eventBus,
      config,
      settings,
    },
    container,
    config,
  );
};

export const renderMicroFrontend = ({
  history,
  match,
  host,
  config,
  name,
  user,
  settings,
  container,
  fetchManifestError,
  userPreferencePromise,
}) => {
  if (fetchManifestError) {
    return;
  }
  userPreferencePromise
    .then((res) => {
      UserPreferenceManager.setAppSetting(name, res.data);
    })
    .catch((err) => {
      /* istanbul ignore next */
      console.log('Load user preference error:', err);
    })
    .finally(() => {
      renderApp({ history, match, host, config, name, user, settings, container });
    });
};

export const renderContainer = ({ name, containerRef }) => (
  <div id="microfrontend-viewport" style={{ height: '100%' }}>
    <div id={`${name.toLowerCase()}-container`} style={{ height: '100%' }}>
      <div
        id={`${name.toLowerCase()}-content`}
        className="microfrontent-content"
        ref={containerRef}
        style={{ height: '100%' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner />
        </div>
      </div>
    </div>
  </div>
);

export const renderError = () => (
  <MessageStrip
    icon="message-error"
    style={{ margin: '10px' }}
    noCloseButton={false}
    type={MessageStripDesign.Negative}
  >
    Failed to load asset manifest, please try again.
  </MessageStrip>
);

const MicroFrontend = ({
  name,
  host,
  match,
  history,
  config,
  settings,
  user,
  getManifest = null,
  getAppPreference = null,
}) => {
  const [fetchManifestError, setFetchManifestError] = useState(false);
  const containerRef = useRef();
  const userPreferencePromise = (getAppPreference || defaultGetAppPreference)(name);
  const navigate = useNavigate();

  useEffect(() => {
    const container = containerRef.current;
    if (name !== 'mfe-login' && !hasLoggedin()) {
      navigate('/login?application=osta-sales-audit');
    } else if (container) {
      fetchManifest({ host, name, getManifest }).then((manifest) => {
        // manifest host is equal to `http://localhost:2120`
        fetchApplicationManifestAssets(
          manifest,
          manifest?.files?.['main.js']?.startsWith('http') ? '' : host,
          name,
          config,
        ).then(
          () => {
            renderMicroFrontend({
              history,
              match,
              host,
              config,
              name,
              user,
              settings,
              container,
              fetchManifestError,
              userPreferencePromise,
            });
          },
          (err) => {
            console.log('Load main asset error:', err);
            setFetchManifestError(true);
          },
        );
      });
    }

    return () => {
      if (fetchManifestError) {
        return;
      }
      unmountApplication(name);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (fetchManifestError) {
    return (
      <DefaultLayout
        match={match}
        history={history}
        config={config}
        user={user}
        settings={settings}
      >
        {renderError()}
      </DefaultLayout>
    );
  }

  const microfrontendContainer = renderContainer({ name, containerRef });

  if (name.toLowerCase() === 'mfe-login') {
    return <div style={{ height: '100%' }}>{microfrontendContainer}</div>;
  }

  return (
    <DefaultLayout match={match} history={history} config={config} settings={settings} user={user}>
      {microfrontendContainer}
    </DefaultLayout>
  );
};

export default MicroFrontend;
