import { configureStore as configureStoreRedux } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

let devToolsExtension = (f) => f;

/* istanbul ignore if  */
if (process.env.NODE_ENV === 'development') {
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__();
  }
}

function configureStore(initialState) {
  const store = configureStoreRedux({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: devToolsExtension(),
  });
  /* istanbul ignore if  */
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default; // eslint-disable-line
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}

export default configureStore();
