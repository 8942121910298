export const loadCSS = (dom, assetName, host, name, filePath, hash) => {
  // load css styles
  // console.log(assetName, filePath, hash);

  const buildCssLink = (resolve, reject) => {
    const styleId = `${assetName}-style-${name.toLowerCase()}`;
    const style = dom.getElementById(styleId);
    if (!style) {
      const cssLink = dom.createElement('link');
      cssLink.id = styleId;
      cssLink.rel = 'stylesheet';
      cssLink.type = 'text/css';
      cssLink.crossOrigin = 'anonymous';
      cssLink.href = `${host}${filePath}?random=${crypto.randomUUID()}`;
      if (hash) {
        cssLink.integrity = hash;
      }
      cssLink.onload = () => resolve();
      cssLink.onerror = (err) => reject(err);
      dom.head.appendChild(cssLink);
    } else {
      resolve();
    }
  };

  return new Promise((resolve, reject) => {
    buildCssLink(resolve, reject);
  });
};

export function getURLParam(url, name) {
  const searchParams = new URLSearchParams(url);
  return searchParams.get(name);
}

export const TestingLocales = {
  '1Q': 'en-US-saptrc',
  '2Q': 'en-US-sappsd',
};
