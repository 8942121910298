/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
import React, { useRef } from 'react';
import { Button, ButtonDesign, FlexBox } from '@ui5/webcomponents-react';
import eurekaMgrs from '@eureka/ui-managers';
import EurekaComponents from 'eureka';

const { useTranslation } = EurekaComponents.I18nProvider;
const { getConfig } = eurekaMgrs.ConfigManager;

const LogoutForm = ({ eventBus }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const { auth_provider, auth_method } = getConfig('user') || {};

  return (
    <FlexBox
      direction='Column'
      justifyContent='SpaceBetween'
      data-testid="logoutFormContainer"
      className="logoutFormContainer">
      <header className="headerContainer">
        <div>
          <h3 className='logoutTitle'>{t('Confirmation')}</h3>
        </div>
      </header>
      <div className="contentContainer">
        <div>
          <form ref={formRef} action="/auth/logout" method="post">
            <input type="hidden" name="auth_provider" value={auth_provider} />
            <input type="hidden" name="auth_method" value={auth_method} />
          </form>
          {t('Logout_Confirmation')}
        </div>
      </div>
      <footer className="footerContainer">
        <Button
          data-testid="logoutNoBtn"
          style={{ padding: '0 9px' }}
          onClick={() => {
            eventBus.emit('dialog-show-hide', '', {
              type: 'cancel',
              data: {
                showDialog: false,
              },
            });
          }}
          design={ButtonDesign.Transparent}
        >
          {t('No')}
        </Button>
        <Button
          data-testid="logoutYesBtn"
          style={{ marginLeft: '10px', padding: '0 9px' }}
          design={ButtonDesign.Emphasized}
          onClick={() => {
            if (formRef.current) {
              eventBus.emit('close-websocket', '', {
                type: 'logout',
                data: {},
              });
              setTimeout(() => {
                formRef.current.submit();
              }, 2000);
              eventBus.emit('dialog-show-hide', '', {
                type: 'confirm',
                data: {
                  showDialog: false,
                },
              });
            }
          }}
        >
          {t('Yes')}
        </Button>
      </footer>
    </FlexBox>
  );
};

export default LogoutForm;