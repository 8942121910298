export const LngCodeMap = {
  'en-US': 'English',
  'fr-FR': 'French',
  'de-DE': 'German',
  'it-IT': 'Italian',
  'pt-BR': 'Portuguese',
  'es-ES': 'Spanish',
};

export const UNIT_ID = '032bf613-9abd-4cc7-8d20-16e0b6e6696b';
export const CONFIG_URL = 'https://prod.pxconfig.cloud.sap/api';

export const PX_TENANT_ROLE = {
  PROD: '04',
  DEV: '07',
};

export const PX_ENV = {
  PROD: 'prod',
  DEV: 'dev',
};

// This host is sourced from values.production.yml
export const PROD_HOST_REGEXP = /^salesaudit\.osta(?:-eu)?\.retail\.industry\.cloud\.sap/;
