/* eslint-disable operator-linebreak */
import React, { Suspense, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EurekaComponents from 'eureka';
import { MessageStrip } from '@ui5/webcomponents-react';
import eurekaMgrs from '@eureka/ui-managers';
import { useNavigate } from 'react-router';
import { Header, SidePanel } from '../common';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';

const { getConfig } = eurekaMgrs.ConfigManager;
const { Spinner } = EurekaComponents.components;

const navigatePage = (props, mfe, router, navigate) => {
  if (
    props.config.components &&
    (props.config.components || []).filter((c) => c.name === mfe).length > 0
  ) {
    navigate(router);
  }
};

export const WelcomePage = (props) => {
  const permissions = getConfig('CurrentUserPermissions') || [];
  const navigate = useNavigate();

  const isAuthorizedForSalesAuditView = () =>
    permissions?.length > 0 && ['POSTRANSACTION.READ'].every((p) => permissions.includes(p));

  const isAuthorizedForChangeLogView = () =>
    permissions?.length > 0 && ['CHANGELOGEVENT.READ'].every((p) => permissions.includes(p));

  const isAuthorizedForGapCheck = () =>
    permissions?.length > 0 && ['GAPCHECK.READ'].every((p) => permissions.includes(p));

  useEffect(() => {
    if (isAuthorizedForSalesAuditView()) {
      // Role for SALES-AUDIT-UI viewer
      navigatePage(props, 'osta-sales-audit-ui', '/osta-sales-audit/transactions', navigate);
    } else if (isAuthorizedForChangeLogView()) {
      // Role for CHANGE-LOG-UI viewer
      navigatePage(props, 'osta-change-log-ui', '/osta-change-log', navigate);
    } else if (isAuthorizedForGapCheck()) {
      // Role for GAP-CHECK-UI viewer
      navigatePage(props, 'osta-gap-check-ui', '/osta-gap-check/transactions', navigate);
    }
  });

  const renderError = (err) => (
    <MessageStrip className="" slot="" style={{}} tooltip="" noCloseButton>
      {err}
    </MessageStrip>
  );

  return (
    <div className="fd-shell fd-shell--fundamentals">
      <div className="fd-shell__header">
        <Suspense fallback={<Spinner />}>
          <Header
            history={props.history}
            config={props.config}
            settings={props.settings}
            eventBus={props.eventBus}
            user={props.user}
          />
          {!isAuthorizedForSalesAuditView() && !isAuthorizedForChangeLogView()
            ? renderError('Not Authorized. Please check with admin. Thanks')
            : null}
        </Suspense>
      </div>
      <div className="fd-shell__app">
        <div className="fd-app">
          <div
            className={`fd-app__navigation fd-app__navigation--vertical${
              props.common.showMenu ? ' show-menu' : ' hide-menu'
            }`}
          >
            <Suspense fallback={<Spinner />}>
              <SidePanel history={props.history} match={props.match} config={props.config} />
            </Suspense>
          </div>
          <main className="fd-app__main">
            <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
              <div className="home-page" />
            </Suspense>
          </main>
        </div>
      </div>
      <ShellDialog key="welcome-page-dialog" />
      <MessageToast key="welcome-page-toast" />
    </div>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
