import React from 'react';
import { MessageStrip, MessageStripDesign } from '@ui5/webcomponents-react';
import EurekaComponents from 'eureka';

const { useTranslation } = EurekaComponents.I18nProvider;

export default (props) => {
  const { t } = useTranslation();
  let errorType = 'general';
  const uuid = props.match.params.id;
  if (uuid) {
    errorType = uuid;
  } else if (/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/gi.test(uuid)) {
    errorType = uuid;
  } else if (uuid.trim() === 'user-not-found') {
    errorType = 'user-not-found';
  } else if (uuid.trim() === 'tenant-not-found') {
    errorType = 'tenant-not-found';
  }
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <div style={{ width: '300px', height: '200px' }}>
        <MessageStrip noCloseButton noIcon={false} type={MessageStripDesign.Negative}>
          {t('Error_ErrorHappened')}
          <br />
          {t('Error_ErrorType')}: {errorType}
          <br />
          {t('Error_ErrorTips')}
        </MessageStrip>
      </div>
    </div>
  );
};
