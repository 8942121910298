import eurekaMgrs from '@eureka/ui-managers';
import { MessageStrip, MessageStripDesign, ToastPlacement } from '@ui5/webcomponents-react';
import EurekaComponents from 'eureka';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const { useTranslation } = EurekaComponents.I18nProvider;
const { eventBus } = eurekaMgrs;

const MessageStripDesignEnum = {
  success: MessageStripDesign.Positive,
  warn: MessageStripDesign.Warning,
  warning: MessageStripDesign.Warning,
  info: MessageStripDesign.Information,
  error: MessageStripDesign.Negative,
  default: MessageStripDesign.Information,
};

const ToastPlacementMap = {
  [ToastPlacement.TopStart]: 'top-left',
  [ToastPlacement.TopCenter]: 'top-center',
  [ToastPlacement.TopEnd]: 'top-right',
  [ToastPlacement.BottomStart]: 'bottom-left',
  [ToastPlacement.BottomCenter]: 'bottom-center',
  [ToastPlacement.BottomEnd]: 'bottom-right',
};

const ToastPlugin = () => {
  const { t } = useTranslation();
  const [toastProps, setToastProps] = useState({
    toastType: 'default',
    toastDuration: 3000,
    toastContent: '',
    toastComponent: null,
    toastPlacement: ToastPlacement.BottomCenter,
    toastIcon: null,
    iconContainerStyles: {},
    iconStyles: {},
  });
  const messageHandler = (message) => {
    if (message.content || message.component) {
      setToastProps({
        toastType: message.type,
        toastContent: message.content,
        toastComponent: message.component,
        toastDuration: message.duration || 3000,
        toastPlacement: message.placement || ToastPlacement.BottomCenter,
        messageStripType: MessageStripDesignEnum[message.type],
        noCloseButton: message.noCloseButton,
      });
    }
  };

  useEffect(() => {
    eventBus.on('on-message-toast', messageHandler);
    return () => {
      eventBus.detach('on-message-toast', messageHandler);
    };
  }, []);

  useEffect(() => {
    if ((toastProps.toastContent && toastProps.toastContent !== '') || toastProps.toastComponent) {
      toast.remove();
      toast.custom(
        ({ id }) => (
          <MessageStrip
            noCloseButton={toastProps.noCloseButton}
            noIcon={false}
            design={toastProps.messageStripType}
            onClose={() => toast.remove(id)}
            style={{ width: '596px' }}
          >
            {toastProps.toastComponent}
            {toastProps.toastContent && t(toastProps.toastContent)}
          </MessageStrip>
        ),
        {
          duration: toastProps.toastDuration,
          position: ToastPlacementMap[toastProps.toastPlacement],
        },
      );
    }
  }, [t, toastProps]);

  return <Toaster />;
};

export default ToastPlugin;
