/* eslint-disable operator-linebreak */
import React, { useCallback, useRef, useState } from 'react';
import { PxApi, ThemeId } from '@sap-px/pxapi';
import eureka from 'eureka';
import {
  CONFIG_URL,
  PROD_HOST_REGEXP,
  LngCodeMap,
  PX_ENV,
  PX_TENANT_ROLE,
  UNIT_ID,
} from 'constants/px.constants';
import { Button, Bar, Modals, FlexBox, ButtonDesign } from '@ui5/webcomponents-react';

const { getCurrentLanguage, useTranslation } = eureka.I18nProvider;

export const usePxFeedback = () => {
  const { t } = useTranslation();
  const pxApi = useRef(new PxApi());
  const showDialog = Modals.useShowDialog();
  const resolveHandler = useRef();
  const [isInitialized, setIsInitialized] = useState(false);

  /**
   * Context data structure:
   * https://pages.github.tools.sap/sap-px/sap-px-api-docs/2.12.0/dataStructures/appContextData/
   * */
  const getAppContextData = () => ({
    appId: 'myAppId',
    appTitle: 'Omnichannel Sales Transfer and Audit',
    languageTag: LngCodeMap[getCurrentLanguage() || 'en-US'],
  });

  const handleDialogClose = useCallback((surveyUser, closeCallback) => {
    const resultData = {
      appContextData: getAppContextData(),
      surveyUser,
    };
    closeCallback?.();
    resolveHandler.current?.(resultData);
  }, []);

  const surveyInvitationDialogCallback = useCallback(() => {
    const { close } = showDialog(
      {
        headerText: t('Feedback_Invitation_Header'),
        children: <div>{t('Feedback_Invitation_Message')}</div>,
        footer: (
          <Bar
            endContent={
              <FlexBox style={{ marginRight: '-12px', gap: '8px' }}>
                <Button
                  onClick={() => handleDialogClose(true, close)}
                  design={ButtonDesign.Emphasized}
                  data-testId="feedbackSubmitBtn"
                >
                  {t('Yes')}
                </Button>
                <Button
                  onClick={() => handleDialogClose(false, close)}
                  data-testId="feedbackDeclineBtn"
                >
                  {t('Not_Now')}
                </Button>
              </FlexBox>
            }
          />
        ),
      },
      document.body,
    );
    return new Promise((resolve) => {
      resolveHandler.current = resolve;
    });
  }, [handleDialogClose, showDialog, t]);

  const init = useCallback(
    async (tenantId) => {
      try {
        if (isInitialized || !pxApi.current) return;

        const tenantInfo = {
          tenantId,
          tenantRole: PROD_HOST_REGEXP.test(window.location.host)
            ? PX_TENANT_ROLE.PROD
            : PX_TENANT_ROLE.DEV,
        };

        const configIdentifier = {
          unitId: UNIT_ID,
          environment: tenantInfo.tenantRole === PX_TENANT_ROLE.DEV ? PX_ENV.DEV : PX_ENV.PROD,
          configUrl: CONFIG_URL,
        };
        pxApi.current.currentThemeId = ThemeId.sap_fiori_3;
        const initialize = await pxApi.current.initialize(
          tenantInfo,
          configIdentifier,
          null,
          surveyInvitationDialogCallback,
        );

        setIsInitialized(!!initialize);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    [isInitialized, surveyInvitationDialogCallback],
  );

  const handleFeedback = async () => {
    await pxApi.current.openSurvey(getAppContextData());
  };

  return {
    pxApi: pxApi.current,
    init,
    isInitialized,
    handleFeedback,
    getAppContextData,
    surveyInvitationDialogCallback,
  };
};
