import React from 'react';
import { Select, Option, Label } from '@ui5/webcomponents-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { useTranslation } from './eureka';

const formItemStyle = {
  width: '100%',
  margin: '15px auto',
  display: 'flex',
  flexDirection: 'column',
};

const labelStyle = {
  width: '100%',
  marginBottom: '4px',
};

const LanguageAndRegion = ({ data, languages, onDataPropChange }) => {
  const { t } = useTranslation('shell');

  return (
    <div style={{ padding: '0 12px' }}>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>
          {t('UserProfile_Language', 'Language', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          onChange={(evt) => {
            const lng = evt.detail.selectedOption.dataset.testid;
            onDataPropChange(lng, 'language');
          }}
          id="language"
          data-testid="languageSelectInput"
          style={{ width: '100%' }}
        >
          {languages.map((language) => (
            <Option
              key={language.languageCode}
              data-testid={language.languageCode}
              value={language.languageCode}
              selected={language.languageCode === data.language}
            >
              {t(language.languageTranslationKey)}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageAndRegion);
